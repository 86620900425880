<template>
  <div class="dashboard workout-library">
    <div class="container">
      <sliderFilter/>
      <div class="workout-library-list">
        <div class="row workout-library-list-row" v-if="showLibrary">
          <div class="col-12 col-lg-4 col-xxl-3 workout-library-list-column" v-for="(item,index) in libraryItems" :key="index">
            <exerciseItem :exerciseItem="item" class="item-mobile-layout-2"/>
          </div>
        </div>
        <p v-if="!showLibrary">Sem resultados</p>
      </div>
    </div>
  </div>
</template>

<script>
//Components
import tabs from "@/components/workouts/workout-by-tabs.vue";
import sliderFilter from "@/components/slider-filter.vue";
import chevronLeft from "@/components/svg/chevron-left.vue";
import exerciseItem from "@/components/exercise/exercise-item.vue";
import axios from "axios";
import {EventBus} from "@/event-bus";

export default {
  name: "library",
  data: function () {
    return {
      lang: this.$root.siteLang,
      libraryItems: [],
      showLibrary: false,
      offset: 0,
      limit: 12,
      scroll: null
    }
  },
  metaInfo () {
    return {
      title: this.$root.translations.biblioteca,
    }
  },
  components: {
    tabs,
    chevronLeft,
    sliderFilter,
    exerciseItem,
  },
  methods: {
    handleScroll() {
      let element = document.getElementsByClassName('box-page-content')[0]
      if ((element.scrollHeight - element.offsetHeight) <= element.scrollTop) {
        this.offset = this.libraryItems.length
        this.limit = 4
        this.getLibraryList()
      }
    },
    getLibraryList() {
      let authKey = window.$cookies.get("authKey");
      this.$root.pageTitle = this.$root.translations.biblioteca;

      axios
          .get(process.env.VUE_APP_API_URL + 'products', {
            params: {
              id_category: 3,
              with_category_title: 0,
              lang: this.lang,
              offset: this.offset,
              limit: this.limit
            },
            headers: {
              Authorization: 'Bearer ' + authKey
            }
          })
          .then((response) => {
            if(response.data.length === 0){
              let element = document.getElementsByClassName('box-page-content')[0]
              element.removeEventListener('scroll', this.handleScroll);
            }else if(this.libraryItems.length > 0){
              for (var elem in response.data) {
                this.libraryItems.push(response.data[elem])
              }
            }else{
              this.libraryItems = response.data;
            }
          })
          .catch((e) => {
            this.errors.push(e);
          }).finally((e) => {
        (this.libraryItems.length > 0) ? this.showLibrary = true : this.showLibrary = false;
      });
    }
  },
  mounted() {
    let element = document.getElementsByClassName('box-page-content')[0]
    element.addEventListener('scroll', this.handleScroll);

    this.getLibraryList()

    EventBus.$on('bus-libraryFilter', (libraryFilter) => {
      let filter = '';
      if (libraryFilter != '' && libraryFilter != undefined) {
        filter = '&starts_by=' + libraryFilter;
      }
      let authKey = window.$cookies.get("authKey");
      axios
          .get(process.env.VUE_APP_API_URL + 'products?id_category=3&with_category_title=0' + filter + '&lang=' + this.lang, {
            headers: {
              Authorization: 'Bearer ' + authKey
            }
          })
          .then((response) => {
            this.libraryItems = response.data;
          })
          .catch((e) => {
            this.errors.push(e);
          }).finally((e) => {
        (this.libraryItems.length > 0) ? this.showLibrary = true : this.showLibrary = false;
      });
    });
  },
  beforeDestroy() {
    let element = document.getElementsByClassName('box-page-content')[0]
    element.removeEventListener('scroll', this.handleScroll);
  }
}
</script>